.jqvmap-zoomin,
.jqvmap-zoomout {
    height: 20px;
    width: 20px;
    line-height: 14px;
    background-color: $primary;
    color: $white;
}

.jqvmap-zoomout {
    top: 35px;
}

#world-map {
    height: 350px;
	@include respond ('phone'){
		height: 200px;
	}
}

.svg-map__location{
	fill: #eff2f4;
	stroke: #eff2f4;
	&:hover{
		fill: #000000;
	}
}