body {
	font-family: 'Poppins', sans-serif;
}

.app {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;

}

.title {
	font-size: 36px;
}

.timer-container {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.timer {
	font-size: 32px;
	padding-left: 16px;
    font-weight: 500;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
}

.timer span {
	font-size: 16px;
	color: #fff;
}

.semicolon {
	font-size: 36px;
}
